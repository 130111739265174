"use strict"

export function initInScope($scope) {

    $("body").on("submit", ".js-combined-article-form", function(e){
/*         e.preventDefault();
        console.log(e); */
    //    $(this).trigger();
    });
    $("body").on("click", ".js-variant-select__combined", function(e){
        $(".js-variant-select-input__combined-number").prop('disabled', true);
        $(this).closest('.js-variant-select-input__combined-number--selected').prop('disabled', true);
        $(this).find('.js-variant-select-input__combined-number').prop('disabled', false);
    });
    $("body").on("click", ".js-combined-select__remove", function(e){
        $(this).parent().find(".js-variant-select-input__combined-number--selected").prop('disabled', true);
        $(this).parent().parent().find('.variant-dropdown__menu').find('input').each(function(){
            $(this).prop('disabled', true);
        });
        $(".js-variant-select-input__combined-number").prop('disabled', true);
        $('.js-combined-article-form').trigger('submit');
    });
    $scope.find(".js-variant-select__combined").each(function () {
      //  $scope.find(".js-variant-select-input__combined-number").prop('disabled', true);
     //   $(this).find('.js-variant-select-input__combined-number').prop('disabled', false);
    });

    $scope.find(".js-variant-select-input__combined-number--selected").each(function () {
        $(this).on("change", function(){
            let name = $(this).attr('name');
            $('input[name="'+name+'"][type="hidden"]').val($(this).val());
        });
    });

    $scope.find(".js-variant-select").each(function () {
        let $variantSelection = $(this);

        $variantSelection.find('.js-variant-select__remove').on('click', function () {
            let target = $($(this).data('target-id'));
            target.prop('checked', false)
            target.trigger('change')
        });
    });
}